// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:3e5f56d7-8276-4dfb-81c4-e31ab186b7bd",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_N7qP1ONs7",
    "aws_user_pools_web_client_id": "6qnoqmdh4bhm0857c5b9opmlh3",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://wytl3ow7jvfgbcm4rcf4ppuyru.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "questionstimuli-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
