import { SET_TAGGED_DOCUMENTS, SET_TOPICS, SET_STANDARDS } from "./smartag.actions";

let defaultState = {
  active: null, // array of 
  tags: [], // array of objects
  topics: [],
  standards: [],
  taggedDocuments: [], // connection bw tags and docs
  filterTypes: [] // array of tag type strings
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case SET_TAGGED_DOCUMENTS:
      return {
        ...state,
        taggedDocuments: action.payload.taggedDocuments
      };
    case SET_TOPICS:
      return {
        ...state,
        topics: action.payload.topics
      };
    case SET_STANDARDS:
      return {
        ...state,
        standards: action.payload.standards
      };
    default:
      return state
  }
}