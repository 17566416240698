import React, { Component } from 'react'
import { AppBar, Tabs, Tab } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

class TopNav extends Component {
  constructor(props) {
    super(props)
    const activeRoute = props.location;
    const tabIndex = props.routes.indexOf(activeRoute.pathname);
    this.state = { value: tabIndex };
  }

  handleChange = (event, value) => {
    this.setState({ value })
    this.props.history.push(this.props.routes[value])
  }

  render() { 
    return (
      <div className="topNav">
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {this.props.titles.map(
              (titleLabel, index) => <Tab label={titleLabel} key={index}/>
            )}
          </Tabs>
        </AppBar>
      </div>
    );
  }
}

TopNav.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string),
  routes: PropTypes.arrayOf(PropTypes.string)
};

export default withRouter(TopNav);