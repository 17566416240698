import { SWITCH_ACTIVE_SUBJECT, STORE_SUBJECTS } from './subject.actions'

let defaultState = {
  active: 'a85228d1-d58f-4978-bf7c-bc41517a2f97',
  subjects: []
}
export default function (state = defaultState, action) {
  switch (action.type) {
    case SWITCH_ACTIVE_SUBJECT:
      return {
        ...state,
        active: action.payload.nextSubject
      };
    case STORE_SUBJECTS:
      return {
        ...state,
        subjects: action.payload.subjects
      };
    default:
      return state;
  }
};
