import {combineReducers} from 'redux';
import subjectReducer from './subject/subject.reducer';
import smarTagReducer from './smartag/smartag.reducers';
import documentReducer from './document/document.reducer';
import questionReducer from './question/question.reducer';

export default combineReducers({
  subject: subjectReducer,
  smarTags: smarTagReducer,
  document: documentReducer,
  question: questionReducer,
});
