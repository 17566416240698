import React, { PureComponent } from 'react'
import TopicsRenderer from '../containers/TopicsRenderer';

export default class GlobalHistoryTenth extends PureComponent {

  render() {
    return (
      <div className="GlobalTenth-container">
        <TopicsRenderer grade="10"></TopicsRenderer>
      </div>
    );
  }
}
