import { API, graphqlOperation } from 'aws-amplify';
import { listDocuments } from '../../graphql/queries';
import store from '../store';

export const SET_DOCUMENTS = 'SET_DOCUMENTS_FROM_SERVER'

export function hydrateEmptyDocuments() {
  const documents = store.getState()['document'].all;
  if (!documents.length) {
    storeDocuments()
  }
}
export async function storeDocuments() {
  return API.graphql(graphqlOperation(listDocuments, {limit: 1000})).then(
    ({ data }) => {
      if (data.listDocuments.items.length) {
        // console.log('Storing documents', data.listDocuments.items)
        store.dispatch(setDocumentsAction(data.listDocuments.items))
      }
    }
  );
}
function setDocumentsAction(documents) {
  return {
    type: SET_DOCUMENTS,
    payload: { documents }
  }
}
