import { SET_DOCUMENTS } from './document.actions'

let defaultState = {
  active: null,
  all: []
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case SET_DOCUMENTS:
      return {
        ...state,
        all: action.payload.documents
      };
    default:
      return state;
  }
};
