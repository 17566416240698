import { SET_ACTIVE_QUESTION } from './question.actions'

let defaultState = {
  active: null
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case SET_ACTIVE_QUESTION:
      return {
        ...state,
        active: action.payload.question
      }
    default:
      return state;
  }
};
