import { API, graphqlOperation } from 'aws-amplify'
import store from '../store'
import { listTaggedDocuments, listTopics, listStandards } from '../../graphql/queries';

export const SET_TOPICS = 'SET_TOPICS'
export const SET_STANDARDS = 'SET_STANDARDS'
export const SET_TAGGED_DOCUMENTS = 'SET_TAGGED_DOCUMENTS'

export function discardTaggedDocuments() {
  const action = setTaggedDocuments([])
  return action;
}

/** STATE FILTERS */
// tools for going from Topic <----> SmarTag <----> TaggedDocument
// and also for Standard <----> SmarTag <----> TaggedDocument
// and also for Skill <----> SmarTag <----> TaggedDocument
export function getTaggedDocumentsFromTag(tagId) {}
export function getSmarTagFromSubtag(subtag) {
  // subtag can be Standard, Skill, or Topic
}

/** HYBRID FRONT/BACKEND METHODS THAT UPDATE STORE */
export function hydrateTaggedDocuments() {
  const taggedDocs = store.getState()['smarTags'].taggedDocuments;
  if (!taggedDocs.length) {
    storeTaggedDocumentList()
  }
}
export async function storeTaggedDocumentList() {
  return API.graphql(graphqlOperation(
    listTaggedDocuments, { limit: 10000 }
  )).then(
    ({ data }) => {
      // console.log('Storing tagged documents', data.listTaggedDocuments.items);
      store.dispatch(
        setTaggedDocuments(data.listTaggedDocuments.items)
      );
      return data.listTaggedDocuments.items;
    }
  );
}
function setTaggedDocuments(taggedDocuments) {
  return {
    type: SET_TAGGED_DOCUMENTS,
    payload: {
      taggedDocuments
    }
  };
}

export function hydrateEmptyTopics() {
  const topics = store.getState()['smarTags'].topics;
  if (!topics.length && !Object.keys(topics).length) {
    storeTopics()
  }
}
export async function storeTopics() {
  return API.graphql(graphqlOperation(
    listTopics, { limit: 1000 }
  )).then(
    ({ data }) => {
      if (data && data.listTopics && data.listTopics.items) {
        // add topics to store from backend
        // console.log('Storing topics', data.listTopics.items);
        const topicsByCode = data.listTopics.items.reduce(
          (topicCodeOrganizer, eachTopic) => {
            if (eachTopic && eachTopic.code) {
              return {
                ...topicCodeOrganizer,
                [eachTopic.code]: eachTopic,
              };
            } else {
              return topicCodeOrganizer;
            }
          }, {}
        )
        store.dispatch(setTopicsAction(topicsByCode))
      }
    }
  );
}
function setTopicsAction(topics) {
  return {
    type: SET_TOPICS,
    payload: { topics }
  };
}

export async function storeStandards() {
  return API.graphql(graphqlOperation(
    listStandards, { limit: 1000 }
  )).then(
    ({ data }) => {
      if (data && data.listStandards && data.listStandards.items) {
        // add standards to store from backend
        // console.log('Storing standards', data.listStandards.items);
        store.dispatch(setStandardsAction(data.listStandards.items))
      }
    }
  );
}
function setStandardsAction(standards) {
  return {
    type: SET_STANDARDS,
    payload: {
      standards
    }
  };
}
