import { createMuiTheme } from '@material-ui/core/styles';

export const BASE_SERIF = 'Merriweather'
export const BASE_SANS_SERIF = 'Nunito'
export const palette = {
  primary: {
    main: '#D90000',
    light: '#dd1a1a',
    dark: '#bf0000'
  },
  secondary: {
    main: '#008282',
    light: '#1a8f8f',
    dark: '#007272'
  },
  error: {
    main: '#FF530C',
    light: '#ff6424',
    dark: '#e0490b'
  },
  contrastThreshold: 3,
  tonalOffset: 2
}
export const theme = createMuiTheme({
  palette,
  typography: {
    fontFamily: [
      'Nunito',
      '-apple-system',
      'Helvetica',
      'sans-serif'
    ].join(',')
  }
});