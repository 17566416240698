// eslint-disable
// this is an auto generated file. This will be overwritten

export const getSubject = `query GetSubject($id: ID!) {
  getSubject(id: $id) {
    id
    name
    code
    icon {
      id
      bucket
      type
      url
      documents {
        items {
          id
        }
        nextToken
      }
      sizes {
        id
        thumb {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        original {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
      }
      height
      width
      name
      alt
      description
    }
    tile {
      id
      bucket
      type
      url
      documents {
        items {
          id
        }
        nextToken
      }
      sizes {
        id
        thumb {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        original {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
      }
      height
      width
      name
      alt
      description
    }
    cover {
      id
      bucket
      type
      url
      documents {
        items {
          id
        }
        nextToken
      }
      sizes {
        id
        thumb {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        original {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
      }
      height
      width
      name
      alt
      description
    }
    description
    createdAt
    updatedAt
    questions {
      items {
        id
        body
        bodyDelta
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        type
        locale
        prompt
        promptDelta
        explanation
        explanationDelta
        source
        access
        author
        choices {
          nextToken
        }
        attachments {
          nextToken
        }
        tags {
          nextToken
        }
        generator {
          id
          type
          body
        }
        createdAt
        updatedAt
        searchable
      }
      nextToken
    }
    topics {
      items {
        id
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        name
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        description
        standards {
          nextToken
        }
        related {
          nextToken
        }
        code
        category
      }
      nextToken
    }
    tags {
      items {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const listSubjects = `query ListSubjects(
  $filter: ModelSubjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      code
      icon {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      tile {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      cover {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      description
      createdAt
      updatedAt
      questions {
        items {
          id
          body
          bodyDelta
          type
          locale
          prompt
          promptDelta
          explanation
          explanationDelta
          source
          access
          author
          createdAt
          updatedAt
          searchable
        }
        nextToken
      }
      topics {
        items {
          id
          name
          description
          code
          category
        }
        nextToken
      }
      tags {
        items {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getQuestion = `query GetQuestion($id: ID!) {
  getQuestion(id: $id) {
    id
    body
    bodyDelta
    subject {
      id
      name
      code
      icon {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      tile {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      cover {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      description
      createdAt
      updatedAt
      questions {
        items {
          id
          body
          bodyDelta
          type
          locale
          prompt
          promptDelta
          explanation
          explanationDelta
          source
          access
          author
          createdAt
          updatedAt
          searchable
        }
        nextToken
      }
      topics {
        items {
          id
          name
          description
          code
          category
        }
        nextToken
      }
      tags {
        items {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        nextToken
      }
    }
    type
    locale
    prompt
    promptDelta
    explanation
    explanationDelta
    source
    access
    author
    choices {
      items {
        id
        createdAt
        updatedAt
        body
        question {
          id
          body
          bodyDelta
          type
          locale
          prompt
          promptDelta
          explanation
          explanationDelta
          source
          access
          author
          createdAt
          updatedAt
          searchable
        }
      }
      nextToken
    }
    attachments {
      items {
        id
        questionSubjectId
        question {
          id
          body
          bodyDelta
          type
          locale
          prompt
          promptDelta
          explanation
          explanationDelta
          source
          access
          author
          createdAt
          updatedAt
          searchable
        }
        document {
          id
          type
          name
          locale
          source
          sourceURL
          heading
          body
          bodyDelta
          description
          createdAt
          updatedAt
        }
      }
      nextToken
    }
    tags {
      items {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        question {
          id
          body
          bodyDelta
          type
          locale
          prompt
          promptDelta
          explanation
          explanationDelta
          source
          access
          author
          createdAt
          updatedAt
          searchable
        }
      }
      nextToken
    }
    generator {
      id
      type
      body
      recommendedResources {
        id
        type
        name
        locale
        source
        sourceURL
        heading
        body
        bodyDelta
        description
        createdAt
        updatedAt
        images {
          nextToken
        }
        questions {
          nextToken
        }
        tags {
          nextToken
        }
      }
      tagAdditions {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
      generatedQuestions {
        items {
          id
          body
          bodyDelta
          type
          locale
          prompt
          promptDelta
          explanation
          explanationDelta
          source
          access
          author
          createdAt
          updatedAt
          searchable
        }
        nextToken
      }
    }
    createdAt
    updatedAt
    searchable
  }
}
`;
export const listQuestions = `query ListQuestions(
  $filter: ModelQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      body
      bodyDelta
      subject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      type
      locale
      prompt
      promptDelta
      explanation
      explanationDelta
      source
      access
      author
      choices {
        items {
          id
          createdAt
          updatedAt
          body
        }
        nextToken
      }
      attachments {
        items {
          id
          questionSubjectId
        }
        nextToken
      }
      tags {
        items {
          id
        }
        nextToken
      }
      generator {
        id
        type
        body
        recommendedResources {
          id
          type
          name
          locale
          source
          sourceURL
          heading
          body
          bodyDelta
          description
          createdAt
          updatedAt
        }
        tagAdditions {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        generatedQuestions {
          nextToken
        }
      }
      createdAt
      updatedAt
      searchable
    }
    nextToken
  }
}
`;
export const getChoice = `query GetChoice($id: ID!) {
  getChoice(id: $id) {
    id
    createdAt
    updatedAt
    body
    question {
      id
      body
      bodyDelta
      subject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      type
      locale
      prompt
      promptDelta
      explanation
      explanationDelta
      source
      access
      author
      choices {
        items {
          id
          createdAt
          updatedAt
          body
        }
        nextToken
      }
      attachments {
        items {
          id
          questionSubjectId
        }
        nextToken
      }
      tags {
        items {
          id
        }
        nextToken
      }
      generator {
        id
        type
        body
        recommendedResources {
          id
          type
          name
          locale
          source
          sourceURL
          heading
          body
          bodyDelta
          description
          createdAt
          updatedAt
        }
        tagAdditions {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        generatedQuestions {
          nextToken
        }
      }
      createdAt
      updatedAt
      searchable
    }
  }
}
`;
export const listChoices = `query ListChoices(
  $filter: ModelChoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listChoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      body
      question {
        id
        body
        bodyDelta
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        type
        locale
        prompt
        promptDelta
        explanation
        explanationDelta
        source
        access
        author
        choices {
          nextToken
        }
        attachments {
          nextToken
        }
        tags {
          nextToken
        }
        generator {
          id
          type
          body
        }
        createdAt
        updatedAt
        searchable
      }
    }
    nextToken
  }
}
`;
export const getQuestionTemplate = `query GetQuestionTemplate($id: ID!) {
  getQuestionTemplate(id: $id) {
    id
    type
    body
    recommendedResources {
      id
      type
      name
      locale
      source
      sourceURL
      heading
      body
      bodyDelta
      description
      createdAt
      updatedAt
      images {
        items {
          id
        }
        nextToken
      }
      questions {
        items {
          id
          questionSubjectId
        }
        nextToken
      }
      tags {
        items {
          id
        }
        nextToken
      }
    }
    tagAdditions {
      id
      type
      title
      locale
      source
      difficulty
      primarySubject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      documents {
        items {
          id
        }
        nextToken
      }
      questions {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
      standard {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        name
        code
        type
        description
        primaryTopic {
          id
          name
          description
          code
          category
        }
        objectives
      }
      topic {
        id
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        name
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        description
        standards {
          nextToken
        }
        related {
          nextToken
        }
        code
        category
      }
      skill {
        id
        name
        description
        code
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
      }
      related {
        items {
          id
          correlation
        }
        nextToken
      }
    }
    generatedQuestions {
      items {
        id
        body
        bodyDelta
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        type
        locale
        prompt
        promptDelta
        explanation
        explanationDelta
        source
        access
        author
        choices {
          nextToken
        }
        attachments {
          nextToken
        }
        tags {
          nextToken
        }
        generator {
          id
          type
          body
        }
        createdAt
        updatedAt
        searchable
      }
      nextToken
    }
  }
}
`;
export const listQuestionTemplates = `query ListQuestionTemplates(
  $filter: ModelQuestionTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuestionTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      body
      recommendedResources {
        id
        type
        name
        locale
        source
        sourceURL
        heading
        body
        bodyDelta
        description
        createdAt
        updatedAt
        images {
          nextToken
        }
        questions {
          nextToken
        }
        tags {
          nextToken
        }
      }
      tagAdditions {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
      generatedQuestions {
        items {
          id
          body
          bodyDelta
          type
          locale
          prompt
          promptDelta
          explanation
          explanationDelta
          source
          access
          author
          createdAt
          updatedAt
          searchable
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getDocument = `query GetDocument($id: ID!) {
  getDocument(id: $id) {
    id
    type
    name
    locale
    source
    sourceURL
    heading
    body
    bodyDelta
    description
    createdAt
    updatedAt
    images {
      items {
        id
        document {
          id
          type
          name
          locale
          source
          sourceURL
          heading
          body
          bodyDelta
          description
          createdAt
          updatedAt
        }
        image {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
      }
      nextToken
    }
    questions {
      items {
        id
        questionSubjectId
        question {
          id
          body
          bodyDelta
          type
          locale
          prompt
          promptDelta
          explanation
          explanationDelta
          source
          access
          author
          createdAt
          updatedAt
          searchable
        }
        document {
          id
          type
          name
          locale
          source
          sourceURL
          heading
          body
          bodyDelta
          description
          createdAt
          updatedAt
        }
      }
      nextToken
    }
    tags {
      items {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        document {
          id
          type
          name
          locale
          source
          sourceURL
          heading
          body
          bodyDelta
          description
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
}
`;
export const listDocuments = `query ListDocuments(
  $filter: ModelDocumentFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      name
      locale
      source
      sourceURL
      heading
      body
      bodyDelta
      description
      createdAt
      updatedAt
      images {
        items {
          id
        }
        nextToken
      }
      questions {
        items {
          id
          questionSubjectId
        }
        nextToken
      }
      tags {
        items {
          id
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getVisualDocuments = `query GetVisualDocuments($id: ID!) {
  getVisualDocuments(id: $id) {
    id
    document {
      id
      type
      name
      locale
      source
      sourceURL
      heading
      body
      bodyDelta
      description
      createdAt
      updatedAt
      images {
        items {
          id
        }
        nextToken
      }
      questions {
        items {
          id
          questionSubjectId
        }
        nextToken
      }
      tags {
        items {
          id
        }
        nextToken
      }
    }
    image {
      id
      bucket
      type
      url
      documents {
        items {
          id
        }
        nextToken
      }
      sizes {
        id
        thumb {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        original {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
      }
      height
      width
      name
      alt
      description
    }
  }
}
`;
export const listVisualDocumentss = `query ListVisualDocumentss(
  $filter: ModelVisualDocumentsFilterInput
  $limit: Int
  $nextToken: String
) {
  listVisualDocumentss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      document {
        id
        type
        name
        locale
        source
        sourceURL
        heading
        body
        bodyDelta
        description
        createdAt
        updatedAt
        images {
          nextToken
        }
        questions {
          nextToken
        }
        tags {
          nextToken
        }
      }
      image {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
    }
    nextToken
  }
}
`;
export const getImage = `query GetImage($id: ID!) {
  getImage(id: $id) {
    id
    bucket
    type
    url
    documents {
      items {
        id
        document {
          id
          type
          name
          locale
          source
          sourceURL
          heading
          body
          bodyDelta
          description
          createdAt
          updatedAt
        }
        image {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
      }
      nextToken
    }
    sizes {
      id
      thumb {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      cover {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      original {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
    }
    height
    width
    name
    alt
    description
  }
}
`;
export const listImages = `query ListImages(
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      bucket
      type
      url
      documents {
        items {
          id
        }
        nextToken
      }
      sizes {
        id
        thumb {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        original {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
      }
      height
      width
      name
      alt
      description
    }
    nextToken
  }
}
`;
export const getResizedImage = `query GetResizedImage($id: ID!) {
  getResizedImage(id: $id) {
    id
    thumb {
      id
      bucket
      type
      url
      documents {
        items {
          id
        }
        nextToken
      }
      sizes {
        id
        thumb {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        original {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
      }
      height
      width
      name
      alt
      description
    }
    cover {
      id
      bucket
      type
      url
      documents {
        items {
          id
        }
        nextToken
      }
      sizes {
        id
        thumb {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        original {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
      }
      height
      width
      name
      alt
      description
    }
    original {
      id
      bucket
      type
      url
      documents {
        items {
          id
        }
        nextToken
      }
      sizes {
        id
        thumb {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        original {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
      }
      height
      width
      name
      alt
      description
    }
  }
}
`;
export const listResizedImages = `query ListResizedImages(
  $filter: ModelResizedImageFilterInput
  $limit: Int
  $nextToken: String
) {
  listResizedImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      thumb {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      cover {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      original {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
    }
    nextToken
  }
}
`;
export const getSmarTag = `query GetSmarTag($id: ID!) {
  getSmarTag(id: $id) {
    id
    type
    title
    locale
    source
    difficulty
    primarySubject {
      id
      name
      code
      icon {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      tile {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      cover {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      description
      createdAt
      updatedAt
      questions {
        items {
          id
          body
          bodyDelta
          type
          locale
          prompt
          promptDelta
          explanation
          explanationDelta
          source
          access
          author
          createdAt
          updatedAt
          searchable
        }
        nextToken
      }
      topics {
        items {
          id
          name
          description
          code
          category
        }
        nextToken
      }
      tags {
        items {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        nextToken
      }
    }
    documents {
      items {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        document {
          id
          type
          name
          locale
          source
          sourceURL
          heading
          body
          bodyDelta
          description
          createdAt
          updatedAt
        }
      }
      nextToken
    }
    questions {
      items {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        question {
          id
          body
          bodyDelta
          type
          locale
          prompt
          promptDelta
          explanation
          explanationDelta
          source
          access
          author
          createdAt
          updatedAt
          searchable
        }
      }
      nextToken
    }
    createdAt
    updatedAt
    standard {
      id
      tag {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
      name
      code
      type
      description
      primaryTopic {
        id
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        name
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        description
        standards {
          nextToken
        }
        related {
          nextToken
        }
        code
        category
      }
      objectives
    }
    topic {
      id
      subject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      name
      tag {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
      description
      standards {
        items {
          id
          name
          code
          type
          description
          objectives
        }
        nextToken
      }
      related {
        items {
          id
          correlation
        }
        nextToken
      }
      code
      category
    }
    skill {
      id
      name
      description
      code
      tag {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
    }
    related {
      items {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        topic {
          id
          name
          description
          code
          category
        }
        correlation
      }
      nextToken
    }
  }
}
`;
export const listSmarTags = `query ListSmarTags(
  $filter: ModelSmarTagFilterInput
  $limit: Int
  $nextToken: String
) {
  listSmarTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      title
      locale
      source
      difficulty
      primarySubject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      documents {
        items {
          id
        }
        nextToken
      }
      questions {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
      standard {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        name
        code
        type
        description
        primaryTopic {
          id
          name
          description
          code
          category
        }
        objectives
      }
      topic {
        id
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        name
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        description
        standards {
          nextToken
        }
        related {
          nextToken
        }
        code
        category
      }
      skill {
        id
        name
        description
        code
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
      }
      related {
        items {
          id
          correlation
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getTopic = `query GetTopic($id: ID!) {
  getTopic(id: $id) {
    id
    subject {
      id
      name
      code
      icon {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      tile {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      cover {
        id
        bucket
        type
        url
        documents {
          nextToken
        }
        sizes {
          id
        }
        height
        width
        name
        alt
        description
      }
      description
      createdAt
      updatedAt
      questions {
        items {
          id
          body
          bodyDelta
          type
          locale
          prompt
          promptDelta
          explanation
          explanationDelta
          source
          access
          author
          createdAt
          updatedAt
          searchable
        }
        nextToken
      }
      topics {
        items {
          id
          name
          description
          code
          category
        }
        nextToken
      }
      tags {
        items {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        nextToken
      }
    }
    name
    tag {
      id
      type
      title
      locale
      source
      difficulty
      primarySubject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      documents {
        items {
          id
        }
        nextToken
      }
      questions {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
      standard {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        name
        code
        type
        description
        primaryTopic {
          id
          name
          description
          code
          category
        }
        objectives
      }
      topic {
        id
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        name
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        description
        standards {
          nextToken
        }
        related {
          nextToken
        }
        code
        category
      }
      skill {
        id
        name
        description
        code
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
      }
      related {
        items {
          id
          correlation
        }
        nextToken
      }
    }
    description
    standards {
      items {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        name
        code
        type
        description
        primaryTopic {
          id
          name
          description
          code
          category
        }
        objectives
      }
      nextToken
    }
    related {
      items {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        topic {
          id
          name
          description
          code
          category
        }
        correlation
      }
      nextToken
    }
    code
    category
  }
}
`;
export const listTopics = `query ListTopics(
  $filter: ModelTopicFilterInput
  $limit: Int
  $nextToken: String
) {
  listTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      subject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      name
      tag {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
      description
      standards {
        items {
          id
          name
          code
          type
          description
          objectives
        }
        nextToken
      }
      related {
        items {
          id
          correlation
        }
        nextToken
      }
      code
      category
    }
    nextToken
  }
}
`;
export const getStandard = `query GetStandard($id: ID!) {
  getStandard(id: $id) {
    id
    tag {
      id
      type
      title
      locale
      source
      difficulty
      primarySubject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      documents {
        items {
          id
        }
        nextToken
      }
      questions {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
      standard {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        name
        code
        type
        description
        primaryTopic {
          id
          name
          description
          code
          category
        }
        objectives
      }
      topic {
        id
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        name
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        description
        standards {
          nextToken
        }
        related {
          nextToken
        }
        code
        category
      }
      skill {
        id
        name
        description
        code
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
      }
      related {
        items {
          id
          correlation
        }
        nextToken
      }
    }
    name
    code
    type
    description
    primaryTopic {
      id
      subject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      name
      tag {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
      description
      standards {
        items {
          id
          name
          code
          type
          description
          objectives
        }
        nextToken
      }
      related {
        items {
          id
          correlation
        }
        nextToken
      }
      code
      category
    }
    objectives
  }
}
`;
export const listStandards = `query ListStandards(
  $filter: ModelStandardFilterInput
  $limit: Int
  $nextToken: String
) {
  listStandards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
      name
      code
      type
      description
      primaryTopic {
        id
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        name
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        description
        standards {
          nextToken
        }
        related {
          nextToken
        }
        code
        category
      }
      objectives
    }
    nextToken
  }
}
`;
export const getSkill = `query GetSkill($id: ID!) {
  getSkill(id: $id) {
    id
    name
    description
    code
    tag {
      id
      type
      title
      locale
      source
      difficulty
      primarySubject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      documents {
        items {
          id
        }
        nextToken
      }
      questions {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
      standard {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        name
        code
        type
        description
        primaryTopic {
          id
          name
          description
          code
          category
        }
        objectives
      }
      topic {
        id
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        name
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        description
        standards {
          nextToken
        }
        related {
          nextToken
        }
        code
        category
      }
      skill {
        id
        name
        description
        code
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
      }
      related {
        items {
          id
          correlation
        }
        nextToken
      }
    }
  }
}
`;
export const listSkills = `query ListSkills(
  $filter: ModelSkillFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      code
      tag {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getTaggedQuestion = `query GetTaggedQuestion($id: ID!) {
  getTaggedQuestion(id: $id) {
    id
    tag {
      id
      type
      title
      locale
      source
      difficulty
      primarySubject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      documents {
        items {
          id
        }
        nextToken
      }
      questions {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
      standard {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        name
        code
        type
        description
        primaryTopic {
          id
          name
          description
          code
          category
        }
        objectives
      }
      topic {
        id
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        name
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        description
        standards {
          nextToken
        }
        related {
          nextToken
        }
        code
        category
      }
      skill {
        id
        name
        description
        code
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
      }
      related {
        items {
          id
          correlation
        }
        nextToken
      }
    }
    question {
      id
      body
      bodyDelta
      subject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      type
      locale
      prompt
      promptDelta
      explanation
      explanationDelta
      source
      access
      author
      choices {
        items {
          id
          createdAt
          updatedAt
          body
        }
        nextToken
      }
      attachments {
        items {
          id
          questionSubjectId
        }
        nextToken
      }
      tags {
        items {
          id
        }
        nextToken
      }
      generator {
        id
        type
        body
        recommendedResources {
          id
          type
          name
          locale
          source
          sourceURL
          heading
          body
          bodyDelta
          description
          createdAt
          updatedAt
        }
        tagAdditions {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        generatedQuestions {
          nextToken
        }
      }
      createdAt
      updatedAt
      searchable
    }
  }
}
`;
export const listTaggedQuestions = `query ListTaggedQuestions(
  $filter: ModelTaggedQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listTaggedQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
      question {
        id
        body
        bodyDelta
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        type
        locale
        prompt
        promptDelta
        explanation
        explanationDelta
        source
        access
        author
        choices {
          nextToken
        }
        attachments {
          nextToken
        }
        tags {
          nextToken
        }
        generator {
          id
          type
          body
        }
        createdAt
        updatedAt
        searchable
      }
    }
    nextToken
  }
}
`;
export const getTaggedDocument = `query GetTaggedDocument($id: ID!) {
  getTaggedDocument(id: $id) {
    id
    tag {
      id
      type
      title
      locale
      source
      difficulty
      primarySubject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      documents {
        items {
          id
        }
        nextToken
      }
      questions {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
      standard {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        name
        code
        type
        description
        primaryTopic {
          id
          name
          description
          code
          category
        }
        objectives
      }
      topic {
        id
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        name
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        description
        standards {
          nextToken
        }
        related {
          nextToken
        }
        code
        category
      }
      skill {
        id
        name
        description
        code
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
      }
      related {
        items {
          id
          correlation
        }
        nextToken
      }
    }
    document {
      id
      type
      name
      locale
      source
      sourceURL
      heading
      body
      bodyDelta
      description
      createdAt
      updatedAt
      images {
        items {
          id
        }
        nextToken
      }
      questions {
        items {
          id
          questionSubjectId
        }
        nextToken
      }
      tags {
        items {
          id
        }
        nextToken
      }
    }
  }
}
`;
export const listTaggedDocuments = `query ListTaggedDocuments(
  $filter: ModelTaggedDocumentFilterInput
  $limit: Int
  $nextToken: String
) {
  listTaggedDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
      document {
        id
        type
        name
        locale
        source
        sourceURL
        heading
        body
        bodyDelta
        description
        createdAt
        updatedAt
        images {
          nextToken
        }
        questions {
          nextToken
        }
        tags {
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getAttachedDocument = `query GetAttachedDocument($id: ID!) {
  getAttachedDocument(id: $id) {
    id
    questionSubjectId
    question {
      id
      body
      bodyDelta
      subject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      type
      locale
      prompt
      promptDelta
      explanation
      explanationDelta
      source
      access
      author
      choices {
        items {
          id
          createdAt
          updatedAt
          body
        }
        nextToken
      }
      attachments {
        items {
          id
          questionSubjectId
        }
        nextToken
      }
      tags {
        items {
          id
        }
        nextToken
      }
      generator {
        id
        type
        body
        recommendedResources {
          id
          type
          name
          locale
          source
          sourceURL
          heading
          body
          bodyDelta
          description
          createdAt
          updatedAt
        }
        tagAdditions {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        generatedQuestions {
          nextToken
        }
      }
      createdAt
      updatedAt
      searchable
    }
    document {
      id
      type
      name
      locale
      source
      sourceURL
      heading
      body
      bodyDelta
      description
      createdAt
      updatedAt
      images {
        items {
          id
        }
        nextToken
      }
      questions {
        items {
          id
          questionSubjectId
        }
        nextToken
      }
      tags {
        items {
          id
        }
        nextToken
      }
    }
  }
}
`;
export const listAttachedDocuments = `query ListAttachedDocuments(
  $filter: ModelAttachedDocumentFilterInput
  $limit: Int
  $nextToken: String
) {
  listAttachedDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      questionSubjectId
      question {
        id
        body
        bodyDelta
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        type
        locale
        prompt
        promptDelta
        explanation
        explanationDelta
        source
        access
        author
        choices {
          nextToken
        }
        attachments {
          nextToken
        }
        tags {
          nextToken
        }
        generator {
          id
          type
          body
        }
        createdAt
        updatedAt
        searchable
      }
      document {
        id
        type
        name
        locale
        source
        sourceURL
        heading
        body
        bodyDelta
        description
        createdAt
        updatedAt
        images {
          nextToken
        }
        questions {
          nextToken
        }
        tags {
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getRelatedTopics = `query GetRelatedTopics($id: ID!) {
  getRelatedTopics(id: $id) {
    id
    tag {
      id
      type
      title
      locale
      source
      difficulty
      primarySubject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      documents {
        items {
          id
        }
        nextToken
      }
      questions {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
      standard {
        id
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        name
        code
        type
        description
        primaryTopic {
          id
          name
          description
          code
          category
        }
        objectives
      }
      topic {
        id
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        name
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        description
        standards {
          nextToken
        }
        related {
          nextToken
        }
        code
        category
      }
      skill {
        id
        name
        description
        code
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
      }
      related {
        items {
          id
          correlation
        }
        nextToken
      }
    }
    topic {
      id
      subject {
        id
        name
        code
        icon {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        tile {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        cover {
          id
          bucket
          type
          url
          height
          width
          name
          alt
          description
        }
        description
        createdAt
        updatedAt
        questions {
          nextToken
        }
        topics {
          nextToken
        }
        tags {
          nextToken
        }
      }
      name
      tag {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
      description
      standards {
        items {
          id
          name
          code
          type
          description
          objectives
        }
        nextToken
      }
      related {
        items {
          id
          correlation
        }
        nextToken
      }
      code
      category
    }
    correlation
  }
}
`;
export const listRelatedTopicss = `query ListRelatedTopicss(
  $filter: ModelRelatedTopicsFilterInput
  $limit: Int
  $nextToken: String
) {
  listRelatedTopicss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag {
        id
        type
        title
        locale
        source
        difficulty
        primarySubject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
        standard {
          id
          name
          code
          type
          description
          objectives
        }
        topic {
          id
          name
          description
          code
          category
        }
        skill {
          id
          name
          description
          code
        }
        related {
          nextToken
        }
      }
      topic {
        id
        subject {
          id
          name
          code
          description
          createdAt
          updatedAt
        }
        name
        tag {
          id
          type
          title
          locale
          source
          difficulty
          createdAt
          updatedAt
        }
        description
        standards {
          nextToken
        }
        related {
          nextToken
        }
        code
        category
      }
      correlation
    }
    nextToken
  }
}
`;
