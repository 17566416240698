import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, Paper, Button } from '@material-ui/core'

class TaggedDocumentDisplay extends Component {
  state = { filteredTaggedDocs: [] }

  static getDerivedStateFromProps(nextProps, prevState) {
    // filter tagged documents based on smarTag filter
    const { filterTag, filterCode, taggedDocs } = nextProps;
    let filteredTaggedDocs = prevState.filteredTaggedDocs;

    // filter based on filterTag
    if (filterTag && taggedDocs.length) {
      filteredTaggedDocs = taggedDocs.filter(
        (taggedDocument) => {
          return taggedDocument.tag.id === filterTag
        }
      );
    } else {
      // empty array or tagged document list depending on filter value
      filteredTaggedDocs = filterTag ? [] : taggedDocs;
    }

    // filter based on filterCode
    if (filterCode && taggedDocs.length) {
      filteredTaggedDocs = taggedDocs.filter(
        (taggedDocument) => {
          let tagCode;
          if (taggedDocument.tag.standard) {
            tagCode = taggedDocument.tag.standard.code
          } else if (taggedDocument.tag.topic) {
            tagCode = taggedDocument.tag.topic.code
          } else {
            return false
          }
          // return tagCode.includes(filterCode);
          const tagCodeSliceLength = filterCode.length;
          const matchingCode = tagCode.slice(0, tagCodeSliceLength);
          const remainingCode = tagCode.slice(tagCodeSliceLength);
          return matchingCode === filterCode && remainingCode.length <= 1;
        }
      );
    } else {
      filteredTaggedDocs = filterCode ? [] : taggedDocs;
    }
    return { filteredTaggedDocs };
  }

  render() {
    return (
      <div className="TaggedDocuments-container">
        {this.state.filteredTaggedDocs.length ? <h4>Available CRQs:</h4> : null}
        {this.state.filteredTaggedDocs.map(
          ({ document }, idx) => this.renderDocument(document, idx)
        )}
      </div>
    );
  }
  renderDocument = (document, key=null) => {
    // Only render google docs
    return document.sourceURL ? (
      <Paper key={key} className="TaggedDocuments-document">
        <p>{(document && document.name) || 'No title'}</p>
        <Button color="secondary">
          <Link href={document.sourceURL} color="secondary">View in Google Docs</Link>
        </Button>
        <hr/>
      </Paper>
    ) : null;
  }
}
// must return an object
const mapStateToProps = (state, _) => ({
  taggedDocs: state.smarTags.taggedDocuments,
  documents: state.document.all
})
export default connect(
  mapStateToProps
)(TaggedDocumentDisplay)

TaggedDocumentDisplay.propTypes = {
  taggedDocs: PropTypes.arrayOf(PropTypes.object),
  filterTag: PropTypes.string, // smarTag ID
  filterCode: PropTypes.string
}
