import React from 'react';
import './App.css';
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './components/theme';
import Amplify from 'aws-amplify';
import amplifyConfig from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react'
import { BrowserRouter as RouterWrapper, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'

import store from './Models/store'
import TopNav from './components/menus/TopNav';
import GlobalHistoryTenth from './Views/GlobalHistoryTenth';
import GlobalHistoryNinth from './Views/GlobalHistoryNinth';
import FeedbackPage from './Views/Feedback';
import { hydrateEmptyDocuments } from './Models/document/document.actions';
import { hydrateTaggedDocuments, hydrateEmptyTopics } from './Models/smartag/smartag.actions';

Amplify.configure(amplifyConfig)

function App() {
  hydrateEmptyDocuments()
  hydrateTaggedDocuments()
  hydrateEmptyTopics()
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <div className="App">
          <RouterWrapper>
            <TopNav 
              titles={['10th Grade', '9th Grade', 'Feedback']}
              routes={['/', '/ninth', '/feedback']}
            ></TopNav>
            <Switch>
              <Route exact path="/" component={GlobalHistoryTenth} />
              <Route exact path="/ninth" component={GlobalHistoryNinth} />
              <Route exact path="/feedback" component={FeedbackPage} />
            </Switch>
          </RouterWrapper>
        </div>
      </Provider>
    </MuiThemeProvider>
  );
}

export default withAuthenticator(App, { includeGreetings: true });
