export const ninthGlobalHistoryTopics = {
  '9.1': ['9.1a', '9.1b', '9.1c'],
  '9.2': ['9.2a', '9.2b'],
  '9.3': ['9.3a', '9.3b', '9.3c', '9.3d'],
  '9.4': ['9.4a', '9.4b', '9.4c', '9.4d'],
  '9.5': ['9.5a', '9.5b', '9.5c'],
  '9.6': ['9.6a', '9.6b'],
  '9.7': ['9.7a', '9.7b', '9.7c'],
  '9.8': ['9.8a', '9.8b', '9.8c'],
  '9.9': ['9.9a', '9.9b', '9.9c', '9.9d', '9.9e'],
  '9.10': ['9.10a', '9.10b', '9.10c', '9.10d', '9.10e'],
}
export const ninthGlobalHistoryTopicOrder = ['9.1', '9.2', '9.3', '9.4', '9.5', '9.6', '9.7', '9.8', '9.9', '9.10']

export const tenthGlobalHistoryTopics = {
  '10.1': ['10.1a', '10.1b'],
  '10.2': ['10.2a', '10.2b', '10.2c', '10.2d'],
  '10.3': ['10.3a', '10.3b', '10.3c', '10.3d'],
  '10.4': ['10.4a', '10.4b', '10.4c'],
  '10.5': ['10.5a', '10.5b', '10.5c', '10.5d', '10.5e'],
  '10.6': ['10.6a', '10.6b', '10.6c'],
  '10.7': ['10.7a', '10.7b', '10.7c', '10.7d'],
  '10.8': ['10.8a', '10.8b'],
  '10.9': ['10.9a', '10.9b', '10.9c', '10.9d'],
  '10.10': ['10.10a', '10.10b', '10.10c'],
}
export const tenthGlobalHistoryTopicOrder = ['10.1', '10.2', '10.3', '10.4', '10.5', '10.6', '10.7', '10.8', '10.9', '10.10']
