import React, { PureComponent } from 'react'
import TopicsRenderer from '../containers/TopicsRenderer';

export default class GlobalHistoryNinth extends PureComponent {
  render() {
    return (
      <div className="GlobalNinth-container">
        <TopicsRenderer grade="9"></TopicsRenderer>
      </div>
    );
  }
}
