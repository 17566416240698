import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { dynamicSort } from '../util/SortableArray'
import { toTitleCase } from '../util/stringOps'
import TaggedDocumentDisplay from './TaggedDocumentDisplay';
import { ninthGlobalHistoryTopicOrder, tenthGlobalHistoryTopicOrder } from '../Models/curriculum/outlines';

class TopicsRenderer extends Component {

  filterTopicsByGrade = () => {
    return this.props.topics.filter(
      ( topic ) => topic && topic.code ? topic.code.includes(this.props.grade + '.') : false
    );
  }

  render() {
    return (
      <div className="TopicsRenderer-container">
        {this.filterTopicsByGrade().map(
          this.renderTopic
        )}
      </div>
    );
  }
  renderTopic = (topic, index) => {
    return (
      <div className="TopicsRenderer-topic" key={index}>
        <p>
          <strong>{topic.code} - {toTitleCase(topic.name)}</strong>
          <br />
          {topic.description}
        </p>
        <div className="TopicsRenderer-documents">
          {this.renderTopicDocuments(topic)}
        </div>
      </div>
    );
  }
  renderTopicDocuments = (topic) => {
    const tagId = topic.tag.id;
    if (tagId) {
      return <TaggedDocumentDisplay filterCode={topic.code}></TaggedDocumentDisplay>;
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state, _) => ({
  // attach sorted topics to props
  topics: [...ninthGlobalHistoryTopicOrder, ...tenthGlobalHistoryTopicOrder].map(
    // create new topic object with intCode (standard or topic number)
    (code) => code in state.smarTags.topics ? state.smarTags.topics[code] : null
  )
})
export default connect(
  mapStateToProps
)(TopicsRenderer)

TopicsRenderer.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.object),
  grade: PropTypes.string.isRequired
}
