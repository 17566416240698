import { API, graphqlOperation } from 'aws-amplify'
import { listSubjects } from '../../graphql/queries'
import store from '../store';

export const STORE_SUBJECTS = 'STORE_SUBJECTS'
export const SWITCH_ACTIVE_SUBJECT = 'SWITCH_ACTIVE'

export function switchSubject(subjectId) {
  return {
    type: SWITCH_ACTIVE_SUBJECT,
    payload: {
      nextSubject: subjectId
    }
  };
}

export function activateSubjects() {
  return API.graphql(graphqlOperation(listSubjects)).then(
    ({ data }) => {
      if (data && data.listSubjects && data.listSubjects.items) {
        // store remote subjects in local state
        store.dispatch(setSubjectsAction(data.listSubjects.items))
      }
    }
  )
}
function setSubjectsAction(subjects) {
  return {
    type: STORE_SUBJECTS,
    payload: {
      subjects
    }
  };
}
